<template>
  <el-dialog
    append-to-body
    width="500px"
    title="开票信息"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        style="margin-top: -20px;"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="发票类型:" prop="type">
          <span>{{form.data.type == 1 ? '普通发票' : '增值税专票'}}</span>
        </el-form-item>
        <el-form-item label="抬头名称:" prop="name">
          <span>{{form.data.name}}</span>
        </el-form-item>
        <el-form-item label="税号:" prop="tax_no">
          <span>{{form.data.tax_no}}</span>
        </el-form-item>
        <div style="font-size:18px;margin-bottom:20px;color:#303133;">其他信息</div>
        <el-form-item label="开户银行:" prop="bank">
          <span>{{form.data.bank}}</span>
        </el-form-item>
        <el-form-item label="银行账号:" prop="bank_no">
          <span>{{form.data.bank_no}}</span>
        </el-form-item>
        <el-form-item label="地址:" prop="address">
          <span>{{form.data.address}}</span>
        </el-form-item>
        <el-form-item label="电话:" prop="phone">
          <span>{{form.data.phone}}</span>
        </el-form-item>
      </el-form>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'InvoiceInfo',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: '',
            name: '',
            tax_no: '',
            bank: '',
            bank_no: '',
            address: '',
            phone: '',
          },
          rules: {}
        },
      }
    },
    methods: {
      getDetail(row) {
        if(!!row.id) {
          this.isChange = true
          common.deepClone(this.form.data, row)
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>
