<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.type" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待开票({{table.total_1}})</el-radio-button>
      <el-radio-button :label="2">已开票({{table.total_2}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="抬头" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请账号" prop="account">
          <el-input
            size="mini"
            v-model.trim="table.params.account"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单编号" prop="order_no">
          <el-input
            size="mini"
            v-model.trim="table.params.order_no"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <VTable
      has-pagionation
      :field="table.params.type == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      :updateKey="updateKey"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:name="{row}">
        <el-link type="primary" @click="handleDetail(row)">{{row.name}}</el-link>
      </template>
      <template v-slot:order_count="{row}">
        <el-link type="primary" @click="handleOrder(row)">{{row.order_count}}笔</el-link>
      </template>
      <template v-slot:type="{row}">
        <span type="primary">{{toStr({1:'普票发票', 2:'增值税专票'},row.type)}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="mergeexportData(row,'/admin/order/mergeExport')">导出明细</el-button>
        <el-button type="text" @click="handleUpload(row)" v-if="table.params.type ==1">上传发票</el-button>
        <el-button type="text" @click="handleOpen(row)" v-if="table.params.type ==2">预览发票</el-button>
        <el-button type="text" @click="handleCancel(row)" v-if="table.params.type ==2 " :disabled="row.status == 2 ? true : false">{{row.status == 2 ? '已作废' : '作废发票'}}</el-button>
      </template>
    </VTable>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div v-for="(item,index) in orderList" :key="index" style="line-height: 30px;">
        <span>订单编号：</span>
        <el-link style="margin-right:30px;color:#2cb167;" type="primary" @click="orderDetail(item.id)">{{item.order_no}}</el-link>
        <span>¥{{item.amount}}</span>
      </div>
    </el-dialog>

    <el-dialog
      title="上传发票"
      :visible.sync="dialogVisibleForm"
      width="460px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="elFormDom2"
        :rules="form.rules"
      >
        <el-form-item label="选择发票" prop="file">
          <el-upload
            class="upload-demo"
            action="/api/common/qiniu/uploadFile"
            :limit="1"
            :headers="{ token: token }"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg, image/png, image/gif, .pdf, .xlsx, .xls "
            >
            <el-button size="small" type="primary" plain>点击上传</el-button>
            <span slot="tip" class="el-upload__tip" style="margin-left:10px;">.pdf格式</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <edit ref="edit"  @refresh="getTable"></edit>
    <detail ref="detail"  @refresh="getTable"></detail>
    <info ref="info"  @refresh="getTable"></info>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Info from './components/Info.vue';
import Detail from '@/layout/OrderDetail.vue';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'InvoiceIndex',
  components: {
    VTable,
    Edit,
    Detail,
    Info,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      loading: false,
      field: [
        { name: "name", label: "抬头",hidden: false },
        { name: "tax_no", label: "税号",hidden: false },
        { name: "apply_name", label: "申请人", hidden: false},
        { name: "apply_account", label: "申请账号", hidden: false},
        { name: "type", label: "发票类型", hidden: false},
        { name: "total_amount", label: "开票金额(元)", showTooltip: true, hidden: false},
        { name: "order_count", label: "订单笔数",width:'100', hidden: false},
        { name: "create_time", label: "申请时间",hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "name", label: "抬头",hidden: false },
        { name: "tax_no", label: "税号",hidden: false },
        { name: "apply_name", label: "申请人", hidden: false},
        { name: "apply_account", label: "申请账号", hidden: false},
        { name: "type", label: "发票类型", hidden: false},
        { name: "total_amount", label: "开票金额(元)", showTooltip: true, hidden: false},
        { name: "order_count", label: "订单笔数",width:'100', hidden: false},
        { name: "create_time", label: "申请时间",hidden: false},
        { name: "deal_name", label: "处理人",hidden: false},
        { name: "deal_time", label: "处理时间",hidden: false},
        { name: "action", label: "操作",width: "220", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          account: '',
          name: '',
          order_no: '',
          type:1,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_1: 0,
        total_2: 0,
      },
      updateKey:new Date()+'',
      orderList: [],
      dialogVisible:false,
      dialogVisibleForm: false,
      form: {
        loading:false,
        data:{
          id:'',
          file: '',
        },
        rules: {},
      },
      fileList: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      this.table.loading = true;
      this.$http.get('/admin/invoice/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.updateKey = new Date()+'';
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
        }
      })
    },
    handleOrder(row) {
      this.dialogVisible = true;
      this.orderList = row.order;
    },
    orderDetail(orderId) {
      this.dialogVisible = false;
      let dom = this.$refs.detail;
      dom.toggle(true);
      dom.getDetail(orderId);
      dom = null;
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleForm = false;
    },
    mergeexportData(row, url) {
      if (!!row.order_ids) {
        let order_ids = row.order_ids.replace(/,/g, "|");
        let str = ""
        let obj = {
          order_ids
        }
        Object.keys(obj).forEach(item => {
            str += `&${item}=${obj[item]}`
        })
        console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    handleCancel(row) {
      this.setConfirm(
        "您确定作废发票吗？作废后需要客户重新提交开票申请?",
        { id: row.id },
        "/admin/invoice/cancel"
      );
    },
    handleUpload(row){
      this.dialogVisibleForm = true;
      this.form.data.id = row.id;
    },
    // 移除图片
    handleRemove(file, fileList) {
      this.form.data.file = "";
      this.fileList = [];
    },
    // 图片上传之前
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    // 图片上传成功
    handleAvatarSuccess(event) {
      this.loading = false;
      if (event.code == 1) {
        this.form.data.file = event.data.all_path_url;
      } else {
        this.$message({
          type: "error",
          message: event.msg,
        });
      }
    },
    uploadSubmit() {
      this.$refs.elFormDom2.validate(valid => {
        if (valid) {
          let _params = {...this.form.data}
          this.$http.post('/admin/invoice/open',_params).then(res => {
            if(res.code ==1) {
              this.$message.success("操作成功！")
              this.dialogVisibleForm = false;
              this.getTable();
            } 
          })
        }
      })
    },
    handleOpen(row) {
      window.open(row.file)
    },
    handleDetail(row) {
      let dom = this.$refs.info;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    }
  }
}
</script>